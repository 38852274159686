import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
//ElectricityPaymentDetailReportDw
const JvDetailReportDw = React.lazy(() => import('./views/Report/JvDetailReportDw'));
const ElectricityPaymentDetailReportDw = React.lazy(() => import('./views/Report/ElectricityPaymentDetailReportDw'));
const CogsDetail = React.lazy(() => import('./views/Report/CogsDetail'));

const PlotSaleReport = React.lazy(() => import('./views/Report/PlotSaleReport'));
const LpPurchaseReport = React.lazy(() => import('./views/NewFolder1/LpPurchaseReport'));
const LPFileSaleSummary = React.lazy(() => import('./views/NewFolder1/LPFileSaleSummary'));
const LPFileVerifySummary = React.lazy(() => import('./views/NewFolder1/LPFileVerifySummary'));
const PlotPaymentReport = React.lazy(() => import('./views/Report/PlotPaymentReport'));
const ExpenseReport = React.lazy(() => import('./views/Report/ExpenseReport'));
const ExpReportBr = React.lazy(() => import('./views/Report/ExpReportBr'));
const ExpReportUsr = React.lazy(() => import('./views/Report/ExReportUsr'));
const PlotTransferPaymentReport = React.lazy(() => import('./views/Report/PlotTransferPaymentReport'));
const VacantPlotReport = React.lazy(() => import('./views/Report/VacantPlotReport'));
const CashierLog = React.lazy(() => import('./views/Report/CashierLog'));
const UserDB = React.lazy(() => import('./views/Report/UserDB'));
const UserDBGrp = React.lazy(() => import('./views/Report/UserDBGrp'));
// UserDB
const EbillSumm = React.lazy(() => import('./views/Report/electricity_bill_summ'));//ClientLogReport

const ClientLogReport = React.lazy(() => import('./views/Report/ClientLogReport'));
const EmVilla = React.lazy(() => import('./views/Report/EmVilla'));
const EmTot = React.lazy(() => import('./views/Report/EmTot'));
const MBC = React.lazy(() => import('./views/Report/MBC'));
//
const MBvilla = React.lazy(() => import('./views/Report/MBvilla'));
const MBTot = React.lazy(() => import('./views/Report/MBTot'));
const VARep = React.lazy(() => import('./views/Report/VARep'));
const SectorSlab = React.lazy(() => import('./views/Report/SectorSlab'));
const UnReconsileReport = React.lazy(() => import('./views/Report/UnReconsileReport'));
const OMBTot = React.lazy(() => import('./views/Report/OMBTot'));


//OMBTot
//UnReconsileReport
//SectorSlab
//
const TexesSumReport = React.lazy(() => import('./views/Report/TexesSumReport'));
const BankSummaryReport = React.lazy(() => import('./views/Misc/BankSummaryReport'));
const UserSummaryReport = React.lazy(() => import('./views/Report/UserSummaryReport'));
const CompanySummaryReport = React.lazy(() => import('./views/Report/CompanySummaryReport'));
const BranchSumReport = React.lazy(() => import('./views/Report/BranchSumReport'));
//BankSummaryReport
//rpttarriftransfer
const RPTTransfet = React.lazy(() => import('./views/Report/rpttarriftransfer'));

const BackupG = React.lazy(() => import('./views/Report/BackupG'));
const Main = React.lazy(() => import('./views/Report/Main'));
const LCM = React.lazy(() => import('./views/Report/LCM'));
const DR = React.lazy(() => import('./views/Report/DR'));
const LPSetting = React.lazy(() => import('./views/Report/LPSetting'));
const BRec = React.lazy(() => import('./views/Report/BRec'));
const Hwbm = React.lazy(() => import('./views/Report/Hwbm'));

const Login = React.lazy(() => import('./views/pages/login/Login2')); 

const Register = React.lazy(() => import('./views/pages/register/Register'));

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));

const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Sector = React.lazy(() => import('./views/Misc/Sector'));

const BillReport = React.lazy(() => import('./views/Report/BillReport'));
const ProfitLoss = React.lazy(() => import('./views/Report/ProfitLoss'));
const TBSummary = React.lazy(() => import('./views/Report/TBSummary'));


const ElectArrsum = React.lazy(() => import('./views/Report/ElectArrsum'));
const Ledger = React.lazy(() => import('./views/Misc/ledger'));



const BankListLedger = React.lazy(() => import('./views/Report/BankListLedger'));
const BankDetailReport = React.lazy(() => import('./views/Report/BankDetailReport'));
const MaintPaymentReportDetail = React.lazy(() => import('./views/Report/MaintPaymentReportDetail'));
const TransferPrptyRpt = React.lazy(() => import('./views/NewFolder1/TransferPrptyRpt'));
const NewConnReport = React.lazy(() => import('./views/NewFolder1/newConnReport')); 
const PlotReport = React.lazy(() => import('./views/NewFolder1/PlotReport')); 
//BankDetailReport
//BankListLedger
//UnReconsillationDw
const UnReconsillationDw = React.lazy(() => import('./views/Report/UnReconsillationDw'));

const TarrifReport = React.lazy(() => import('./views/Report/TarifReport'));
const PaymentDetail1 = React.lazy(() => import('./views/Report/PaymentDetail'));
const PaymentSummary = React.lazy(() => import('./views/Report/PaymentSummary'));
const TaxesReport = React.lazy(() => import('./views/Report/TaxesReport'));
const LedgerElectric = React.lazy(() => import('./views/Report/LedgerElectric'));

const Landproviderledger = React.lazy(() => import('./views/Misc/landproviderledger'));
const VendorReport = React.lazy(() => import('./views/Misc/VendorReport'));
const AgingReport = React.lazy(() => import('./views/Misc/AgingReport'));
const MemberReport = React.lazy(() => import('./views/Misc/MemberReport'));
const MemberReporta = React.lazy(() => import('./views/Misc/MemberReport1'));
const BallotReport = React.lazy(() => import('./views/Report/BallotReport'));
const Sub_lpReports = React.lazy(() => import('./views/Misc/sub_lpReports'));
const TaxesDetailReport = React.lazy(() => import('./views/Report/TexesDetailReport'));
const StockReport = React.lazy(() => import('./views/Report/StockReport'));
const FilePhis = React.lazy(() => import('./views/Report/FilePhis'));
//UnitSlabVillaCount
const UnitSlabVillaCount = React.lazy(() => import('./views/Report/UnitSlabVillaCount'));
const CustomerReport = React.lazy(() => import('./views/NewFolder1/CustomerReport'));
const ConnectionLedger = React.lazy(() => import('./views/NewFolder1/ConnectionLedger'));
const ConnectionLedgerDetail = React.lazy(() => import('./views/NewFolder1/ConnectionLedgerDetail'));

const VillaLedger = React.lazy(() => import('./views/NewFolder1/VillaLedger'));
//NewConnMeterReport
const NewConnMeterReport = React.lazy(() => import('./views/Report/NewConnMeterReport'));
const MeterReplacementReport = React.lazy(() => import('./views/Report/MeterReplacementReport'));

const NewPlotReportDetail = React.lazy(() => import('./views/Report/NewPlotReportDetail'));
 

const BankDaybookReport = React.lazy(() => import('./views/Misc/BankDaybookReport'));

//CustomerReport
//LedgerElectric
//TaxesDetailReport
//TaxesReport
//PaymentSummary
//Elect_Bill
//payment_detail_rpt
class App extends Component {

  render() {
    return (

      <BrowserRouter>
          <React.Suspense fallback={loading}>
          <Switch>

            <Route exact path="/Report/ElecDetailReport" name="ElectricityPaymentDetailReportDw" render={props => <ElectricityPaymentDetailReportDw {...props} />} />
            <Route exact path="/Report/CogsDetail" name="CogsDetail" render={props => <CogsDetail {...props} />} />

            <Route exact path="/Report/TexesSumReport" name="TexesSumReport" render={props => <TexesSumReport {...props} />} />

            <Route exact path="/Report/BankSummaryReport" name="BankSummaryReport" render={props => <BankSummaryReport {...props} />} />
            <Route exact path="/Report/UserSummaryReport" name="UserSummaryReport" render={props => <UserSummaryReport {...props} />} />
            <Route exact path="/Report/CompanySummaryReport" name="CompanySummaryReport" render={props => <CompanySummaryReport {...props} />} />
            <Route exact path="/Report/BranchSumReport" name="BranchSumReport" render={props => <BranchSumReport {...props} />} />
            
            <Route exact path="/Report/JvDetailReportDw" name="JvDetailReportDw" render={props => <JvDetailReportDw {...props} />} />
            <Route exact path="/Report/PlotSaleReport" name="PlotSaleReport" render={props => <PlotSaleReport {...props} />} />
            <Route exact path="/Report/LpPurchaseReport" name="LpPurchaseReport" render={props => <LpPurchaseReport {...props} />} />
            <Route exact path="/Report/LPFileSaleSummary" name="LPFileSaleSummary" render={props => <LPFileSaleSummary {...props} />} />

            <Route exact path="/Report/LPFileVerifySummary" name="LPFileVerifySummary" render={props => <LPFileVerifySummary {...props} />} />

            <Route exact path="/Report/PlotPaymentReport" name="PlotPaymentReport" render={props => <PlotPaymentReport {...props} />} />
            <Route exact path="/Report/ExpenseReport" name="ExpenseReport" render={props => <ExpenseReport {...props} />} />
                    <Route exact path="/Report/ExpReportBr" name="ExpReportBr" render={props => <ExpReportBr {...props} />} />
                    <Route exact path="/Report/ExpReportUsr" name="ExpReportUsr" render={props => <ExpReportUsr {...props} />} />
            <Route exact path="/Report/PlotTransferPaymentReport" name="PlotTransferPaymentReport" render={props => <PlotTransferPaymentReport {...props} />} />
            <Route exact path="/Report/VacantPlotReport" name="VacantPlotReport" render={props => <VacantPlotReport {...props} />} />
            <Route exact path="/Report/MaintDetailReport" name="MaintPaymentReportDetail" render={props => <MaintPaymentReportDetail {...props} />} />
            <Route exact path="/Report/UnReconsileReport" name="UnReconsileReport" render={props => <UnReconsileReport {...props} />} />
            <Route exact path="/Report/UnReconcillation" name="UnReconsillationDw" render={props => <UnReconsillationDw {...props} />} />

            <Route exact path="/Report/OmaintSum" name="OMBTot" render={props => <OMBTot {...props} />} />
            <Route exact path="/Report/BankDaybookReport" name="BankDaybookReport" render={props => <BankDaybookReport {...props} />} />
            
            <Route exact path="/Report/TransferReport" name="UnitSlabVillaCount" render={props => <TransferPrptyRpt {...props} />} />       
            <Route exact path="/Report/VillaUnitCount" name="UnitSlabVillaCount" render={props => <UnitSlabVillaCount {...props} />} />
            <Route exact path="/Report/OV/ClientLog" name="ClientLogReport" render={props => <ClientLogReport {...props} />} />
            <Route exact path="/Report/LedgerElectric" name="LedgerElectric" render={props => <LedgerElectric {...props} />} />
            <Route exact path="/Report/Generalledger" name="Landproviderledger" render={props => <Landproviderledger {...props} />} />
            <Route exact path="/Report/MemberReport" name="MemberReport" render={props => <MemberReport {...props} />} />
            <Route exact path="/Report/MemberReportD" name="MemberReporta" render={props => <MemberReporta {...props} />} />
            <Route exact path="/Report/BallotReport" name="BallotReport" render={props => <BallotReport {...props} />} />
            <Route exact path="/Report/ProfitLoss" name="Landproviderledger" render={props => <ProfitLoss {...props} />} />
            <Route exact path="/Report/VendorReport" name="VendorReport" render={props => <VendorReport {...props} />} />
            <Route exact path="/Report/AgingReport" name="AgingReport" render={props => <AgingReport {...props} />} />
            <Route exact path="/Report/Sub_lpReports" name="Sub_lpReports" render={props => <Sub_lpReports {...props} />} />



            <Route exact path="/Report/TaxesReport" name="TaxesReport" render={props => <TaxesReport {...props} />} />
            <Route exact path="/Report/TaxesSummary" name="TaxesDetailReport" render={props => <TaxesDetailReport {...props} />} />
            <Route exact path="/Report/PaymentSummary" name="PaymentSummary" render={props => <PaymentSummary {...props} />} />
            <Route exact path="/Report/EbillSumm" name="EbillSumm" render={props => <EbillSumm {...props} />} />
            <Route exact path="/Report/CashierLog" name="CashierLog" render={props => <CashierLog {...props} />} />
                    <Route exact path="/Report/UserDB" name="UserDB" render={props => <UserDB {...props} />} />
                    <Route exact path="/Report/UserDBGrp" name="UserDBGrp" render={props => <UserDBGrp {...props} />} />
            <Route exact path="/Report/EmVilla" name="EmVilla" render={props => <EmVilla {...props} />} />
            <Route exact path="/Report/EmTot" name="EmTot" render={props => <EmTot {...props} />} />
            <Route exact path="/Report/MBC" name="MBC" render={props => <MBC {...props} />} />
            <Route exact path="/Report/MBvilla" name="MBvilla" render={props => <MBvilla {...props} />} />
            <Route exact path="/Report/MBTot" name="MBTot" render={props => <MBTot {...props} />} />
            <Route exact path="/Report/VARep" name="VARep" render={props => <VARep {...props} />} />
            <Route exact path="/Report/PlotReport" name="PlotReport" render={props => <PlotReport {...props} />} />
            <Route exact path="/Report/SectorSlab" name="VARep" render={props => <SectorSlab {...props} />} />
            <Route exact path="/Report/NewConnection" name="NewConnReport" render={props => <NewConnReport {...props} />} />
         
            <Route exact path="/Report/TariffTransfer" name="TariffTransfer" render={props => <RPTTransfet {...props} />} />
            <Route exact path="/Report/VillaLedger" name="VillaLedger" render={props => <VillaLedger  {...props} />} />  
         

            <Route exact path="/Report/ConnectionLedgerDetail" name="ConnectionLedgerDetail" render={props => <ConnectionLedgerDetail  {...props} />} />    
            <Route exact path="/Report/ConnectionLedger" name="ConnectionLedger" render={props => <ConnectionLedger  {...props} />} />    
            <Route exact path="/Report/CustomerReport" name="CustomerReport" render={props => <CustomerReport {...props} />} />
            <Route exact path="/Report/stock" name="ElectArrsum" render={props => <ElectArrsum {...props} />} />
            <Route exact path="/Report/ledger" name="Ledger" render={props => <Ledger {...props} />} />



            <Route exact path="/Report/BackupG" name="BackupG" render={props => <BackupG {...props} />} />
            <Route exact path="/Report/Main" name="Main" render={props => <Main {...props} />} />
            <Route exact path="/Report/LCM" name="LCM" render={props => <LCM {...props} />} />
            <Route exact path="/Report/DR" name="DR" render={props => <DR {...props} />} />
            <Route exact path="/LPSetting" name="LPSetting" render={props => <LPSetting {...props} />} />
            <Route exact path="/Report/BRec" name="BRec" render={props => <BRec {...props} />} />
            <Route exact path="/Report/Hwbm" name="Hwbm" render={props => <Hwbm {...props} />} />


            <Route exact path="/Report/PaymentDetail" name="PaymentDetail1" render={props => <PaymentDetail1 {...props} />} />
            <Route exact path="/Report/TarrifReport" name="TarrifReport" render={props => <TarrifReport {...props} />} />
            <Route exact path="/Report/TrialBalance" name="BillReport" render={props => <BillReport {...props} />} />
            <Route exact path="/Report/ProfitLoss" name="ProfitLoss" render={props => <ProfitLoss {...props} />} />
            <Route exact path="/Report/TBSummary" name="TBSummary" render={props => <TBSummary {...props} />} />
             <Route exact path="/Report/BankList" name="BankListLedger" render={props => <BankListLedger {...props} />} />
            <Route exact path="/Report/NewConnMeterReport" name="NewConnMeterReport" render={props => <NewConnMeterReport {...props} />} />

            <Route exact path="/Report/MeterReplacementReport" name="MeterReplacementReport" render={props => <MeterReplacementReport {...props} />} />
            <Route exact path="/Report/NewPlotReportDetail" name="NewPlotReportDetail" render={props => <NewPlotReportDetail {...props} />} />

            
            <Route exact path="/Report/BankDetailReport" name="BankDetailReport" render={props => <BankDetailReport {...props} />} />
            <Route exact path="/Report/StockReport" name="StockReport" render={props => <StockReport {...props} />} />
            <Route exact path="/Report/FileHistory" name="FilePhis" render={props => <FilePhis {...props} />} />

            <Route exact path="Misc/Sector" name="Sector Page" render={props => <Sector {...props} />} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> 
 
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />


           
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
